import React from "react";
import { Link } from "gatsby";
import NewsletterImage from "../../images/newsletter-image.jpg";

const NewsletterCTA = () => {
  return (
    <div className="newsletter-cta">
      <div className="newsletter-cta__image">
        <img loading="lazy" src={NewsletterImage} alt="Decorative" />
      </div>
      <div className="newsletter-cta__content">
        <div className="newsletter-cta__text">
          <h2>Stay up to date with {process.env.GATSBY_SITE_NAME}</h2>
          <p>
            ¡Suscríbete a nuestro boletín de noticias y no te pierdas lo último
            en entretenimiento y lifestyle! Recibirás actualizaciones
            exclusivas, consejos de moda, reseñas de películas y mucho más.
            Únete a nuestra comunidad y descubre todo lo que tenemos para
            ofrecerte. ¡Regístrate ahora!
          </p>
          <Link to="/es/contacto/" className="button">
            Regístrate ahora
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewsletterCTA;
